/* eslint-disable max-lines */
import { CookGQLError } from './base/cook-error';
import type { Pick2 } from './utils/pick2';
import type { TextualArticle } from './textual-article';
import type { AudioVisualArticle } from './audio-visual-article';
import type {
  AuthorProfileData,
  ExtraPageData,
  GalleryData,
  IndexPageData,
  IndexPageWithSubsections,
  IndexPageMostRecentData,
  IndexPageEceFrontPageData,
  IndexPageSearchData,
  IndexPageTagData,
  PictureData,
  RssFeedData,
  SponsoredArticleData,
  TagIntroData,
  VisualStoryData,
  RecirculationObject,
} from './queries';

export type QueryResultData =
  | AudioVisualArticle
  | AuthorProfileData
  | ExtraPageData
  | GalleryData
  | IndexPageData
  | IndexPageMostRecentData
  | IndexPageEceFrontPageData
  | IndexPageSearchData
  | IndexPageTagData
  | IndexPageWithSubsections
  | PictureData
  | TagIntroData
  | TextualArticle
  | RssFeedData;

export type ContextQueryResultData = Pick<QueryResultData, 'context' | 'tag' | 'tagIdentifier' | 'tagTitle' | 'tagUri'>;

export type ResolutionContextQueryResultData = Pick2<QueryResultData, 'resolution', 'context' | 'remainingPath'> &
  Pick<QueryResultData, 'footerMenuESI' | 'headerMenuESI'>;

export type ResolutionPublicationQueryResultData = Pick2<QueryResultData, 'resolution', 'publication'>;

export type ResolutionSectionQueryResultData = Pick2<QueryResultData, 'resolution', 'section'>;

export type ResolutionQueryResultData = ResolutionContextQueryResultData &
  ResolutionPublicationQueryResultData &
  ResolutionSectionQueryResultData;

export type ChannelQueryResultData = Pick<QueryResultData, 'channel'>;

export type GrapeshotData = {
  grapeshot?: {
    channels: string[];
  };
};

export type MainContentQueryResultData = ContextQueryResultData & ResolutionQueryResultData & ChannelQueryResultData;

export type QueryResult<T = QueryResultData> = Record<`${number}`, CookGQLError> & {
  errors?: CookGQLError[];
  data: T;
};

export const isAudioVisualArticleContext = (context?: unknown): context is AudioVisualArticle['context'] => {
  return (
    !!context &&
    typeof context === 'object' &&
    '__typename' in context &&
    typeof context.__typename === 'string' &&
    ['LiveVideo', 'Podcast', 'Video'].includes(context.__typename)
  );
};

export const isTextualArticleContext = (context?: unknown): context is TextualArticle['context'] => {
  return (
    !!context &&
    typeof context === 'object' &&
    '__typename' in context &&
    typeof context.__typename === 'string' &&
    ['Feature', 'Interview', 'NewsArticle', 'Opinion', 'SponsoredArticle', 'VisualStory'].includes(context.__typename)
  );
};

export const isSponsoredArticleContext = (
  context?: ContextQueryResultData['context'],
): context is SponsoredArticleData['context'] => {
  return !!context && context.__typename === 'SponsoredArticle';
};

export const isVisualStoryContext = (context?: ContextQueryResultData['context']): context is VisualStoryData['context'] => {
  return !!context && context.__typename === 'VisualStory';
};

export const isExtraPageContext = (context?: ContextQueryResultData['context']): context is ExtraPageData['context'] => {
  return !!context && context.__typename === 'ExtraPage';
};

export const isAuthorProfileContext = (context?: ContextQueryResultData['context']): context is AuthorProfileData['context'] => {
  return !!context && context.__typename === 'AuthorProfile';
};

export const isContextWithElements = (
  context?: ContextQueryResultData['context'],
): context is TextualArticle['context'] | ExtraPageData['context'] => {
  return isTextualArticleContext(context) || isExtraPageContext(context);
};

export function isArticleContext(
  context?: ContextQueryResultData['context'],
): context is AudioVisualArticle['context'] | TextualArticle['context'] {
  return isAudioVisualArticleContext(context) || isTextualArticleContext(context);
}

export const isArticleOrExtraPageContext = (
  context?: ContextQueryResultData['context'],
): context is TextualArticle['context'] | AudioVisualArticle['context'] | ExtraPageData['context'] => {
  return isArticleContext(context) || isExtraPageContext(context);
};

export const isArticleOrAuthorPageContext = (
  context: ContextQueryResultData['context'],
): context is TextualArticle['context'] | AudioVisualArticle['context'] | AuthorProfileData['context'] => {
  return isArticleContext(context) || isAuthorProfileContext(context);
};

export const isSectionPageContext = (
  context?: ContextQueryResultData['context'],
): context is
  | IndexPageData['context']
  | IndexPageEceFrontPageData['context']
  | IndexPageMostRecentData['context']
  | IndexPageWithSubsections['context'] => {
  return !!context && context.__typename === 'SectionPage';
};

export const isMySectionPageContext = (
  context?: ContextQueryResultData['context'],
): context is IndexPageWithSubsections['context'] => {
  return isSectionPageContext(context) && context?.rootGroup.type === 'mySectionLayout';
};

export const isMhluHomePageContext = (
  context?: ContextQueryResultData['context'],
): context is IndexPageEceFrontPageData['context'] => {
  return isSectionPageContext(context) && context?.rootGroup.type === 'mhluHomepage';
};

export const isMhluSectionPageContext = (context?: ContextQueryResultData['context']): context is IndexPageData['context'] => {
  return isSectionPageContext(context) && context?.rootGroup.type === 'mhluSectionpage';
};

export const isSearchPageResolution = (
  resolution?: ResolutionQueryResultData['resolution'],
): resolution is IndexPageSearchData['resolution'] => {
  return !!resolution && resolution.section.uniqueName === 'search';
};

export const isHomePageResolution = (
  resolution?: ResolutionQueryResultData['resolution'],
): resolution is IndexPageEceFrontPageData['resolution'] => {
  return !!resolution && resolution.section.uniqueName === 'ece_frontpage';
};

export const isMostRecentPageResolution = (
  resolution?: ResolutionQueryResultData['resolution'],
): resolution is IndexPageMostRecentData['resolution'] => {
  return isSectionPageResolution(resolution) && resolution.section.uniqueName === 'mostrecent';
};

export const isArticlePageResolution = (
  resolution?: ResolutionQueryResultData['resolution'],
): resolution is
  | AudioVisualArticle['resolution']
  | AuthorProfileData['resolution']
  | GalleryData['resolution']
  | TextualArticle['resolution']
  | VisualStoryData['resolution']
  | ExtraPageData['resolution'] => {
  return !!resolution && resolution.context === 'art';
};

export const isSectionPageResolution = (
  resolution?: ResolutionQueryResultData['resolution'],
): resolution is
  | IndexPageData['resolution']
  | IndexPageEceFrontPageData['resolution']
  | IndexPageMostRecentData['resolution']
  | IndexPageSearchData['resolution']
  | IndexPageWithSubsections['resolution']
  | IndexPageTagData['resolution'] => {
  return !!resolution && resolution.context === 'sec';
};

export const isSectionPage = (
  data?: MainContentQueryResultData,
): data is Pick<IndexPageData, keyof MainContentQueryResultData> => {
  return !!data && isSectionPageContext(data.context);
};

export const isHomePage = (
  data?: MainContentQueryResultData,
): data is Pick<IndexPageEceFrontPageData, keyof MainContentQueryResultData> => {
  return isSectionPage(data) && isHomePageResolution(data.resolution);
};

export const isMhluSectionPage = (
  data?: MainContentQueryResultData,
): data is Pick<IndexPageData, keyof MainContentQueryResultData> => {
  return isSectionPage(data) && isMhluSectionPageContext(data.context);
};

export const isMostRecentPage = (
  data?: MainContentQueryResultData,
): data is Pick<IndexPageMostRecentData, keyof MainContentQueryResultData> => {
  return !!data && isMostRecentPageResolution(data.resolution);
};

export const isMySectionPage = (
  data?: MainContentQueryResultData,
): data is Pick<IndexPageWithSubsections, keyof MainContentQueryResultData> => {
  return isSectionPage(data) && isMySectionPageContext(data.context);
};

export const isNonHomeSectionPage = (
  data?: MainContentQueryResultData,
): data is Pick<IndexPageData | IndexPageMostRecentData | IndexPageWithSubsections, keyof MainContentQueryResultData> => {
  return isSectionPage(data) && !isHomePageResolution(data.resolution);
};

export const isSearchPage = (
  data?: MainContentQueryResultData,
): data is Pick<IndexPageSearchData, keyof MainContentQueryResultData> => {
  return !!data && isSearchPageResolution(data.resolution);
};

export const isTagPage = (
  data?: MainContentQueryResultData,
): data is Pick<IndexPageTagData, keyof MainContentQueryResultData> => {
  return !!data && ('tagIdentifier' in data || 'tag' in data);
};

export const isB2bTagPage = (
  data?: MainContentQueryResultData,
): data is Pick<IndexPageTagData, keyof MainContentQueryResultData> => {
  if (!isTagPage(data)) {
    return false;
  }
  const identifier = data.tagIdentifier || data.tag?.identifier;
  if (!identifier) {
    return false;
  }
  return identifier.includes('b2b@');
};

export const isTagIntroArticle = (
  data?: MainContentQueryResultData,
): data is Pick<IndexPageTagData, keyof MainContentQueryResultData> => {
  return !!data && ['TagIntro', 'TagIntroB2B'].includes((data as TagIntroData).context?.__typename);
};

export const isRssFeed = (data?: MainContentQueryResultData): data is Pick<RssFeedData, keyof MainContentQueryResultData> => {
  return !!data && 'channel' in data;
};

export const isAuthorProfilePage = (
  data?: MainContentQueryResultData,
): data is Pick<AuthorProfileData, keyof MainContentQueryResultData> => {
  return !!data && data.context?.__typename === 'AuthorProfile';
};

export const isTextualArticlePage = (
  data?: MainContentQueryResultData,
): data is Pick<TextualArticle, keyof MainContentQueryResultData> => {
  return !!data && isArticleContext(data.context);
};

export const isExtraPage = (data?: MainContentQueryResultData): data is Pick<ExtraPageData, keyof MainContentQueryResultData> => {
  return !!data && isExtraPageContext(data.context);
};

export const isRecirculationObject = (data: unknown): data is RecirculationObject => {
  return !!data && typeof data === 'object' && 'recirculationSection' in data;
};
