import { useSyncExternalStore } from 'react';

import { ZEPHR_DATA_SCRIPT_ID, PaywallData } from '@hubcms/domain-zephr';

const defaultZephrData = {
  result: null,
  paywallConfig: null,
  subscriber: null,
  reason: 'server-render',
};

const zephrData = (() => {
  if (typeof document === 'undefined') {
    return defaultZephrData;
  }

  const textContent = document.getElementById(ZEPHR_DATA_SCRIPT_ID)?.textContent;

  if (!textContent) {
    return {
      ...defaultZephrData,
      reason: 'script-not-found',
    };
  }

  const scriptContent = JSON.parse(textContent);

  return {
    result: scriptContent.result || null,
    paywallConfig: scriptContent.config || null,
    subscriber: scriptContent.subscriber || null,
    reason: scriptContent.result?.reason || null,
  };
})();

function subscribe() {
  return () => null;
}

function getSnapshot() {
  return zephrData;
}

function getServerSnapshot() {
  return defaultZephrData;
}

export const useZephrData = (): PaywallData => {
  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
};
