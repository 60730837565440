import { domain } from '@hubcms/brand';
import { useTrackingData } from '@hubcms/data-access-tracking';
import { isAboshopLink, isStringInHostname, useExternalConsentUrl } from '@hubcms/utils-navigation';
import { createSubscriptionShopUtmParams, mapSubscriptionShopUtmParams } from '@hubcms/utils-tracking';
import { addSearchParams, createUrl } from '@hubcms/utils-url';
import { LinkText, Paragraph } from '@mediahuis/chameleon-react';
import cx from 'classnames';
import { ElementType, FC, PropsWithoutRef, useEffect, useMemo, useState } from 'react';

import { NavigationItemProps } from './types';
import { createNavigationItemClickHandler } from './createNavigationItemClickHandler';

import styles from './navigation-item.module.scss';

const NavigationItem: FC<NavigationItemProps> = ({
  isActive = false,
  href: initialHref,
  serverHref = initialHref,
  clientHref = initialHref,
  trackingName,
  trackingBlock,
  target,
  className,
  children,
  onClick,
  ...props
}: NavigationItemProps) => {
  const [href, setHref] = useState(serverHref);

  const trackingData = useTrackingData();

  const url = href && createUrl(href);

  const isAboshopDomain = url ? isAboshopLink(url) : false;
  const isSiteDomain = url ? isStringInHostname(url, domain) : false;

  const externalUrl = useExternalConsentUrl(url?.toString());

  if (url && isSiteDomain && isAboshopDomain) {
    const aboshopUtmData = mapSubscriptionShopUtmParams({
      // eslint-disable-next-line camelcase
      utm_artid: trackingData.pageid,
      // eslint-disable-next-line camelcase
      utm_internal: trackingBlock,
    });
    const aboshopUtmParams = createSubscriptionShopUtmParams(aboshopUtmData);
    url.search = addSearchParams(url.search, aboshopUtmParams);
  }

  const onClickHandler = useMemo(() => {
    return createNavigationItemClickHandler(onClick, trackingBlock, trackingName, href, isAboshopDomain, isSiteDomain);
  }, [href, trackingBlock, trackingName, onClick, isAboshopDomain, isSiteDomain]);

  let Component: ElementType = Paragraph;
  let defaultProps: Record<string, unknown> = {};

  if (href) {
    Component = LinkText;
  } else if (onClick) {
    Component = 'button';
    defaultProps = { role: 'button', type: 'button' } as Partial<PropsWithoutRef<JSX.IntrinsicElements['button']>>;
  }

  useEffect(() => {
    setHref(clientHref);
  }, [clientHref]);

  return (
    <Component
      className={cx(
        styles.navigationItem,
        { [styles.active]: isActive, [styles.pointer]: Boolean(onClick || props.onMouseUp) },
        className,
      )}
      href={externalUrl || url?.toString()}
      target={target ?? (href ? '_self' : undefined)}
      onClick={onClickHandler}
      {...defaultProps}
      {...props}
    >
      {children}
    </Component>
  );
};

export default NavigationItem;
