import { useMemo, useCallback } from 'react';
import { loginLabel, logoutLabel, authMethod } from '@hubcms/brand';

import type { AuthMethod } from '@hubcms/domain-auth';
import { useAuth } from '@hubcms/data-access-auth';
import { NavItem } from '@hubcms/domain-navigation';
import { useEnv } from '@hubcms/data-access-env';
import { sendTrackingEvent } from '@hubcms/data-access-tracking';
import { createMenuEvent } from '@hubcms/utils-tracking';
import { UserMenu } from '@hubcms/ui-navigation';

type UserMenuWrapperProps = {
  userMenuItems: NavItem[];
};

function UserMenuWrapper({ userMenuItems }: UserMenuWrapperProps) {
  const env = useEnv();
  const { isAuthenticated, user, logout, login } = useAuth();
  const logoutHref = useMemo(() => {
    if ((authMethod as AuthMethod) === 'oauth' && env?.LOGOUT_BASE_URL && typeof window !== 'undefined') {
      return `${env.LOGOUT_BASE_URL}${encodeURIComponent(window.location.href.toString())}`;
    }

    return '';
  }, [env?.LOGOUT_BASE_URL]);
  const loginHref = '/auth/login/';

  const handleLogin = useCallback(async () => {
    await sendTrackingEvent(createMenuEvent({ eventlabel: 'login' }));
    login(loginHref);
  }, [login]);

  const handleLogout = useCallback(async () => {
    await sendTrackingEvent(createMenuEvent({ eventlabel: 'logout' }));
    logout(logoutHref);
  }, [logout, logoutHref]);

  if (!authMethod || authMethod === 'none') {
    return null;
  }

  return (
    <UserMenu
      isAuthenticated={isAuthenticated}
      user={user}
      loginLabel={loginLabel}
      logoutLabel={logoutLabel}
      userMenuItems={userMenuItems}
      loginAction={handleLogin}
      logoutAction={handleLogout}
    />
  );
}

export default UserMenuWrapper;
